import { useTranslation } from "react-i18next";
import { TenantResponseFull } from "../../models/tenant";
import { useCliftContext } from "../../hooks/useCliftContext";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";
import FormField from "../../components/FormField";
import "./TenantFormAdminsPage.css";

interface TenantFormAdminsPageProps {
  originalTenant?: TenantResponseFull | undefined;
  tenant: Omit<TenantResponseFull, "id" | "tenant">;
  showSave: boolean;
  admins: string[];
  onCancel: () => void;
  onNext?: () => void;
  onBack?: () => void;
  onAddAdmin: () => void;
  onDeleteAdmin: (index: number) => void;
  onAdminChanged: (index: number, value: string) => void;
}

export const TenantFormAdminsPage = ({
  originalTenant,
  showSave,
  admins,
  onAdminChanged,
  onCancel,
  onBack,
  onNext,
  onAddAdmin,
  onDeleteAdmin,
}: TenantFormAdminsPageProps) => {
  const { t } = useTranslation();
  const { cliftState } = useCliftContext();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isRequiredFieldsFilled = (): boolean => {
    // There's always an admins array where the first element is '' so we need to check
    // the required field in a different way
    if (admins[0] && admins[0].length > 0) {
      return emailRegex.test(admins[0]);
    }
    return false;
  };

  return (
    <div className="tenant-form-admins-page">
      <div className="tenant-form-admins-page-creation-row">
        <div>
          <h3>{t("tenant_admins", { ns: "tenant" })}</h3>
          <h4>{t("tenant_admins_hint", { ns: "tenant" })}</h4>
        </div>

        <button
          className="button create-admin-button"
          id="create_admin_button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onAddAdmin?.();
          }}
        >
          <PlusIcon />
          {t("new_admin", { ns: "tenant" })}
        </button>
      </div>

      <div className="tenant-form-admins-page-users">
        {admins.map((admin, i) => (
          <div key={i} className="tenant-form-admins-page-user-item">
            <FormField
              title={t("email", { ns: "contact" })}
              name={"email"}
              type="email"
              autoComplete="off"
              value={admin}
              onValueChanged={(_, val) => onAdminChanged(i, val as string)}
              required
            />
            {i > 0 && (
              <button
                className="tenant-form-admins-page-delete-button"
                title={t("save", { ns: "translation" })}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteAdmin(i);
                }}
              >
                <DeleteIcon />
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="tenant-form-admins-page-email">
        <datalist id="users">
          {originalTenant
            ? cliftState.users
                .filter((user) => user.tenant?.id == originalTenant!.id)
                .map((user) => <option key={user.id}>{user.email}</option>)
            : null}
        </datalist>
      </div>
      <SideModalButtonBar
        nextDisabled={!isRequiredFieldsFilled()}
        showSave={showSave}
        onBack={() => onBack?.()}
        onNext={() => onNext?.()}
        onCancel={onCancel}
      />
    </div>
  );
};
