import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./LiftList.css";
import List from "../../components/List";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DeviceListItem } from "../../models/device-list-item";

export interface LiftSimpleListProps {
  devices: DeviceListItem[];
}

export const LiftSimpleList = ({ devices }: LiftSimpleListProps) => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<DeviceListItem>();

  const columns: ColumnDef<DeviceListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("serialNumber", {
        header: () => t("serial_number", { ns: "lift" }),
        cell: (info) => info.row.original.serialNumber,
      }),
      columnHelper.accessor("model", {
        header: () => t("model", { ns: "lift" }),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("state", {
        header: () => t("state", { ns: "lift" }),
        cell: (info) =>
          info.getValue()
            ? t("activated", { ns: "lift" })
            : t("inactive", { ns: "lift" }),
      }),
    ],
    [i18n.language]
  );

  return (
    <List<DeviceListItem> columns={columns} data={devices} showSearch={true} />
  );
};
