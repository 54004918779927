import { useEffect, useState } from "react";
import { ContactInfo } from "../../../models/device";
import {
  ContactType,
  DependableContactRequest,
  EditContactInfoRequest,
} from "../../../models/contact";
import { useTranslation } from "react-i18next";
import { Option, EntityPicker } from "../../../components/EntityPicker";
import SideModal from "../../../components/SideModal";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { SideModalButtonBar } from "../../../components/SideModalButtonBar";
import { patchPhoneNumbers } from "../../../services/devices-api";
import {
  fetchContacts,
  fetchTenantsContacts,
} from "../../../services/contacts-api";
import "./LiftDetails.css";
import "./EditContactInfo.css";

export interface EditContactInfoProps {
  deviceId: number;
  initialContactInfo: ContactInfo;
}

const toEditRequest = (contactInfo: ContactInfo): EditContactInfoRequest => {
  return {
    speedDialContactId: contactInfo?.speedDialNumber?.id,
    emergencyNumberContactId: contactInfo?.emergencyNumber?.id,
    supportNumberContactId: contactInfo?.supportNumber?.id,
    dependableContacts: contactInfo?.dependableContacts?.map((c) => {
      return {
        priority: c.priority,
        contactId: c.contactId,
      } as DependableContactRequest;
    }),
  } as EditContactInfoRequest;
};

export const EditContactInfo = ({
  deviceId,
  initialContactInfo,
}: EditContactInfoProps) => {
  const { dispatchCliftState, cliftState } = useCliftContext();
  const [editedContactInfo, setContactInfo] = useState<EditContactInfoRequest>(
    toEditRequest(initialContactInfo)
  );
  const [customerContactOptions, setCustomerContactOptions] = useState<
    Option[]
  >([]);
  const [tenantContactOptions, setTenantContactOptions] = useState<Option[]>(
    []
  );
  const handleClose = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };
  const { t } = useTranslation();
  const editDependableContact = (priority: number, id: number) => {
    const dependableContacts = editedContactInfo.dependableContacts;
    const existing = dependableContacts.find((dc) => dc.priority === priority);

    if (existing) {
      existing.contactId = id;
    } else {
      dependableContacts.push({
        contactId: id,
        priority,
      });
    }

    setContactInfo({
      ...editedContactInfo,
      dependableContacts,
    });
  };

  const getDependableContactPicker = (priority: number) => {
    return (
      <EntityPicker
        noCurrentText={t("select_dependable_person", { ns: "lift" })}
        noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
        items={customerContactOptions}
        currentId={
          editedContactInfo.dependableContacts?.find(
            (c) => c.priority == priority
          )?.contactId
        }
        onSelect={function (id: number): void {
          editDependableContact(priority, id);
        }}
      />
    );
  };
  useEffect(() => {
    fetchContacts(initialContactInfo.customerId)
      .then((res) =>
        setCustomerContactOptions(
          res.map((c) => {
            return {
              label: [c.displayName, c.firstName, c.lastName, c.phoneNr].join(
                ", "
              ),
              id: c.id,
            };
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
    fetchTenantsContacts(cliftState.currentTenant!.id)
      .then((res) =>
        setTenantContactOptions(
          res.map((c) => {
            const label =
              c.contactType === ContactType.EMERGENCY
                ? c.phoneNr
                : [c.displayName, c.phoneNr].join(", ");
            return {
              label: label,
              id: c.id,
            };
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }, [cliftState.currentTenant]);
  return (
    <SideModal title={t("calls", { ns: "lift" })} onClose={handleClose}>
      <div className="one-below-another">
        <div className="lift-details-section">
          {/* SPEED DIAL */}
          <EntityPicker
            noCurrentText={t("select_contact", { ns: "lift" })}
            noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
            title={t("speed_dial", { ns: "lift" })}
            items={tenantContactOptions}
            required={true}
            currentId={editedContactInfo.speedDialContactId}
            onSelect={function (id: number): void {
              setContactInfo({
                ...editedContactInfo,
                speedDialContactId: id,
              });
            }}
          />
          {/* EMERGENCY NUMBER */}
          <EntityPicker
            noCurrentText={t("select_contact", { ns: "lift" })}
            noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
            title={t("emergency", { ns: "lift" })}
            required={true}
            items={tenantContactOptions}
            currentId={editedContactInfo.emergencyNumberContactId}
            onSelect={function (id: number): void {
              setContactInfo({
                ...editedContactInfo,
                emergencyNumberContactId: id,
              });
            }}
          />
        </div>

        <div className="lift-details-section">
          <div className="info-titles">
            <h1
              id="dependable-contacts-edit-title"
              className="lift-details-col"
            >
              {t("dependable_contacts_edit_title", { ns: "lift" })}
            </h1>
            <h2
              id="optional-text"
              className="lift-details-col lift-details-secondary-text"
            >
              {t("optional", { ns: "lift" })}
            </h2>
          </div>

          {/* DP 0 */}
          {getDependableContactPicker(1)}
          {/* DP 1 */}
          {getDependableContactPicker(2)}
          {/* DP 2 */}
          {getDependableContactPicker(3)}
        </div>

        {/* SUPPORT NUMBER */}
        <EntityPicker
          noCurrentText={t("select_contact", { ns: "lift" })}
          noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
          title={t("support_number", { ns: "lift" })}
          items={tenantContactOptions}
          required={true}
          currentId={editedContactInfo.supportNumberContactId}
          onSelect={function (id: number): void {
            setContactInfo({
              ...editedContactInfo,
              supportNumberContactId: id,
            });
          }}
        />
      </div>
      <div className="bottom-buttons-bar">
        <SideModalButtonBar
          showSave={true}
          saveDisabled={
            !editedContactInfo.emergencyNumberContactId ||
            !editedContactInfo.speedDialContactId ||
            !editedContactInfo.supportNumberContactId
          }
          onCancel={handleClose}
          onSave={() => {
            patchPhoneNumbers(
              cliftState.currentTenant?.id || 0,
              deviceId,
              editedContactInfo
            )
              .then(() =>
                dispatchCliftState({
                  type: CliftReducerAction.CloseSidePanel,
                })
              )
              .then(() => window.location.reload())
              .catch((err) => console.log(err));
          }}
        />
      </div>
    </SideModal>
  );
};
