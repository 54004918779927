import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useEffect, useState } from "react";
import { toast } from "react-toast";
import Page from "../../components/Page";
// TODO: Add this on later Jira ticket
//import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { LiftList } from "./LiftList";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import {
  CustomerDevice,
  removeDeviceFromCustomer,
} from "../../services/customers-api";
import { LiftSimpleList } from "./LiftSimpleList";
import {
  fetchAllTenantDevices,
  fetchDevices,
} from "../../services/devices-api";
import { DeviceListItem } from "../../models/device-list-item";
import {
  removeDeviceFromTenant,
  TenantDevice,
} from "../../services/tenants-api";

export const LiftsPage = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [selectedDevice, setSelectedDevice] = useState<CustomerDevice>();
  const [devices, setDevices] = useState<DeviceListItem[]>();
  const [selectedTenantDevice, setSelectedTenantDevice] =
    useState<TenantDevice>();

  useEffect(() => {
    if (
      cliftState.currentTenant &&
      cliftState.currentTenant.tenantType &&
      devices === undefined
    ) {
      const promise = ["ROOT", "COUNTRY"].includes(
        cliftState.currentTenant.tenantType
      )
        ? fetchAllTenantDevices(cliftState.currentTenant?.id)
        : fetchDevices(cliftState.currentTenant?.id);

      promise
        .then((res) => {
          setDevices(res);
        })
        .catch((err) => {
          setDevices([]);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("lift_list_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [cliftState.currentTenant, devices]);

  useEffect(() => {
    if (!cliftState.sidepanelContent) {
      setDevices(undefined);
    }
  }, [cliftState.sidepanelContent]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!devices) {
    return <progress></progress>;
  }

  const checkRemoveErrors = (err: Error) => {
    dispatchCliftState({
      type: CliftReducerAction.AddAlert,
      alert: t("delete_device_http_fail", {
        ns: "alerts",
        code: getErrorCode(err),
        reason: getLocalizedErrorReason(err),
      }),
    });
    toast.error(
      t("error") +
        t("delete_device_toast_error", {
          ns: "alerts",
          reason: getLocalizedErrorReason(err),
        })
    );
  };

  const handleRemoveDeviceFromCustomer = async () => {
    if (selectedDevice === undefined) return;

    await removeDeviceFromCustomer(selectedDevice).catch((err) => {
      checkRemoveErrors(err);
    });

    setSelectedDevice(undefined);
    setDevices(undefined);
  };

  const handleRemoveDeviceFromTenant = async () => {
    if (selectedTenantDevice === undefined) return;

    await removeDeviceFromTenant(selectedTenantDevice).catch((err) => {
      checkRemoveErrors(err);
    });

    setSelectedTenantDevice(undefined);
    setDevices(undefined);
  };

  return (
    <Page
      title={t("lifts", { ns: "lift" })}
      subtitle={t("lifts_subtitle", { ns: "lift" })}
      // TODO: Add lift button implementation in later ticket
      /*button={
        <button
          className="button"
          type="button"
          onClick={() => {
            // Add new Lift
            // Implement this on ticket: CLIFT-282
          }}
        >
          <PlusIcon />

          {t("add_new_lift", { ns: "lift" })}
        </button>
      }*/
    >
      <>
        {cliftState.currentTenant.tenantType === "ROOT" ||
        cliftState.currentTenant.tenantType === "COUNTRY" ? (
          <LiftSimpleList devices={devices} />
        ) : (
          <LiftList
            devices={devices}
            tenant={cliftState.currentTenant}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            setSelectedTenantDevice={setSelectedTenantDevice}
          />
        )}

        {selectedDevice !== undefined && (
          <ConfirmDelete
            title={t("remove_lift_question", { ns: "dialogs" })}
            text={t("confirm_lift_remove_question", {
              ns: "dialogs",
              device: selectedDevice.deviceID,
            })}
            isOpen={selectedDevice !== undefined}
            onCancel={() => setSelectedDevice(undefined)}
            onConfirmed={() => {
              handleRemoveDeviceFromCustomer();
            }}
          />
        )}

        {selectedTenantDevice !== undefined && (
          <ConfirmDelete
            title={t("remove_lift_question", { ns: "dialogs" })}
            text={t("confirm_lift_remove_from_tenant_question", {
              ns: "dialogs",
              device: selectedTenantDevice.deviceID,
            })}
            isOpen={selectedTenantDevice !== undefined}
            onCancel={() => setSelectedTenantDevice(undefined)}
            onConfirmed={() => {
              handleRemoveDeviceFromTenant();
            }}
          />
        )}
      </>
    </Page>
  );
};
