import { CustomerListItem } from "models/customer-list-item";
import { Contact } from "../models/contact";
import {
  Customer,
  CustomerDeviceFormValues,
  CustomerFormValues,
  CustomerFullResponse,
} from "../models/customer";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export type CustomerDevice = {
  customerID?: number | undefined;
  deviceID?: number;
  removedFromCustomer?: boolean;
};

export const fetchCustomer = (
  customerID?: number
): Promise<CustomerFullResponse> =>
  fetch(`/api/customers/${customerID}`, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchCustomers = (
  tenantID?: number
): Promise<CustomerListItem[]> =>
  fetch(`/api/tenant/${tenantID}/customers`, {
    method: "POST",
    // TODO: Paging, sort and search to be implemented
    body: JSON.stringify({
      pageSize: 100,
      pageNumber: 0,
      search: "",
      sort: { column: "NAME", direction: "ASC" },
    }),
  })
    .then(parseResponse)
    .then((res) => res.content)
    .catch((e) => handleError(e));

export const createCustomer = (
  formValues: CustomerFormValues,
  tenantId: number,
  contacts: Contact[]
): Promise<void> =>
  fetch("/api/customers/add", {
    method: "POST",
    body: JSON.stringify({
      // TODO: Backend sequence or something to create customer_id
      // Or remove this and use id instead
      customerId: "1",
      firstName: formValues.firstName?.length ? formValues.firstName : null,
      lastName: formValues.lastName?.length ? formValues.lastName : null,
      tenantId: tenantId > 0 ? tenantId : null,
      organization: formValues.organization?.length
        ? formValues.organization
        : null,
      vat: formValues.vat?.length ? formValues.vat : null,
      email: formValues.email?.length ? formValues.email : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      address: formValues.address?.length ? formValues.address : null,
      apartmentNr: formValues.apartmentNr?.length
        ? formValues.apartmentNr
        : null,
      city: formValues.city?.length ? formValues.city : null,
      zipCode: formValues.zipCode?.length ? formValues.zipCode : null,
      region: formValues.region?.length ? formValues.region : null,
      country: formValues.country?.length ? formValues.country : null,
      contacts,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const deleteCustomer = (customerID: number): Promise<void> =>
  fetch(`/api/customer/${customerID}`, {
    method: "DELETE",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const addDeviceToCustomer = (
  customerID: number,
  devices: CustomerDeviceFormValues[]
): Promise<void> =>
  fetch(`/api/customers/${customerID}/devices`, {
    method: "POST",
    body: JSON.stringify(devices),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const removeDeviceFromCustomer = (
  customerLift: CustomerDevice
): Promise<void> =>
  fetch(
    `/api/customers/${customerLift.customerID}/devices/${customerLift.deviceID}`,
    {
      method: "DELETE",
    }
  )
    .then(parseResponse)
    .catch((e) => handleError(e));

export const updateCustomer = (
  customer: Customer,
  formValues: CustomerFormValues,
  tenantId: number,
  contacts: Contact[]
): Promise<void> =>
  fetch(`/api/customers/${customer.id}`, {
    method: "PUT",
    body: JSON.stringify({
      customerId: "1",
      firstName: formValues.firstName?.length ? formValues.firstName : null,
      lastName: formValues.lastName?.length ? formValues.lastName : null,
      tenantId: tenantId > 0 ? tenantId : null,
      organization: formValues.organization?.length
        ? formValues.organization
        : null,
      vat: formValues.vat?.length ? formValues.vat : null,
      email: formValues.email?.length ? formValues.email : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      address: formValues.address?.length ? formValues.address : null,
      apartmentNr: formValues.apartmentNr?.length
        ? formValues.apartmentNr
        : null,
      city: formValues.city?.length ? formValues.city : null,
      zipCode: formValues.zipCode?.length ? formValues.zipCode : null,
      region: formValues.region?.length ? formValues.region : null,
      country: formValues.country?.length ? formValues.country : null,
      contacts,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
