import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CustomerDevice } from "../../services/customers-api";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { TenantDevice } from "services/tenants-api";

export const LiftOptionsMenu = ({
  customerID,
  deviceID,
  tenantID,
  selectedDevice,
  setSelectedDevice,
  setSelectedTenantDevice,
}: {
  customerID: number | null;
  deviceID: number;
  tenantID: number | null;
  selectedDevice?: CustomerDevice | undefined;
  setSelectedDevice(selectedDevice: CustomerDevice): void;
  setSelectedTenantDevice(selectedTenantDevice: TenantDevice): void;
}) => {
  const { cliftState, hasTenantRole } = useCliftContext();
  const { t } = useTranslation();

  const handleRemoveDevice = async () => {
    if (customerID !== null && customerID !== undefined) {
      setSelectedDevice({ customerID, deviceID, removedFromCustomer: true });
    }
  };

  const handleRemoveTenantDevice = async () => {
    if (tenantID !== null && tenantID !== undefined) {
      setSelectedTenantDevice({ tenantID, deviceID });
    }
  };

  return (
    <ContextMenu
      items={[
        {
          id: "delete",
          title: t("remove_lift_from_customer", { ns: "lift" }),
          disabled:
            (selectedDevice?.removedFromCustomer === true &&
              deviceID === selectedDevice.deviceID) ||
            customerID === undefined,
          hidden: !hasTenantRole([cliftState.currentTenant?.id.toString()]),
          onClicked: handleRemoveDevice,
          icon: <DeleteIcon />,
        },
        {
          id: "delete",
          title: t("remove_lift_from_tenant", { ns: "lift" }),
          disabled:
            (selectedDevice?.deviceID === deviceID &&
              selectedDevice.removedFromCustomer !== true) ||
            (customerID !== undefined && selectedDevice?.deviceID !== deviceID),
          hidden: !hasTenantRole([cliftState.currentTenant?.id.toString()]),
          onClicked: handleRemoveTenantDevice,
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
